import { createGlobalStyle } from 'styled-components';

import Light from '../Assets/Fonts/Rowdies-Light.ttf';
import Bold from '../Assets/Fonts/Rowdies-Bold.ttf';
import Regular from '../Assets/Fonts/Rowdies-Regular.ttf';

const ResetStyle = createGlobalStyle`
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    article, aside, details, figcaption, figure, 
    footer, header, hgroup, menu, nav, section {
        display: block;
    }
    body {
        line-height: 1;
    }
    ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    body {
        background: ${({ theme }) => theme.body};
        color: ${({ theme }) => theme.text};
        border: ${({ theme }) => theme.toggleBorder};
    }

     
    @font-face {
        font-family: light;
        src: url(${Light});
    }
    
    @font-face {
        font-family: bold;
        src: url(${Bold});
    }

    @font-face {
        font-family: regular;
        src: url(${Regular});
    }

    h1 {
        font-family: bold;
        font-size: 2rem;
        letter-spacing: 1px;
    }

    h2 {
        font-family: bold;
        font-size: 1.5rem;
        letter-spacing: 1px;
    }
    
    h3 {
        font-family: bold;
        font-size: 1rem;
        letter-spacing: 1px;
    }

    p {
        font-family: light;
        letter-spacing: 1px;
    }

    a {
        font-family: light;
        letter-spacing: 1px;
    }
`;

export default ResetStyle;
